import React, { FC, Suspense } from 'react';

import AppointmentCalendarContainerSkeleton from '../../TakeInterventionAppointmentContainer/TakeInterventionAppointmentContainer.skeleton';

import { AppointmentCalendarContainer } from './AppointmentCalendarContainer';

interface InterventionAppointmentContentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

const TakeInterventionAppointment: FC<
  React.PropsWithChildren<InterventionAppointmentContentProps>
> = ({ children, fileId, sellerId, onSubmit, isLoading = false }) => {
  return (
    <Suspense fallback={<AppointmentCalendarContainerSkeleton />}>
      <AppointmentCalendarContainer
        fileId={fileId}
        sellerId={sellerId}
        isLoading={isLoading}
        onSubmit={onSubmit}
      >
        {children}
      </AppointmentCalendarContainer>
    </Suspense>
  );
};

TakeInterventionAppointment.displayName = 'TakeInterventionAppointment';

export default TakeInterventionAppointment;
