import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Img } from '@savgroup-front-common/core/src/atoms/Img';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { InitContext } from 'myaccount/view/app/NewLayout/InitProvider/InitProvider.context';

import {
  HandlingValues,
  PickupPointOption,
} from '../../../../../../IrshPages.types';
import messages from '../messages';

import { ChoosePickupPointCard } from './ChoosePickupPointCard/ChoosePickupPointCard';
import { getUrl } from './PickupPointList.helpers';
import { $Container } from './PickupPointList.styles';

interface PickupPointListProps {
  onSelectPickupPoint: (pickupPointId: PickupPointOption) => void;
  pickupPoints: PickupPointOption[];
  handlingValues: HandlingValues;
}

const PickupPointList: React.FC<PickupPointListProps> = ({
  onSelectPickupPoint,
  pickupPoints,
  handlingValues,
}) => {
  const intl = useIntl();

  const { orders } = useContext(InitContext);

  const sellerId = orders?.at(0)?.sellerId;

  return (
    <>
      <$Container>
        {pickupPoints.map((pickupPointOption) => {
          const pickupPoint = pickupPointOption.data.pickupPoint;

          const normalizedDistance =
            pickupPoint.distanceInMeters < 1000
              ? pickupPoint.distanceInMeters
              : Math.round(pickupPoint.distanceInMeters / 100) / 10;
          const distanceUnit = pickupPoint.distanceInMeters < 1000 ? 'm' : 'km';

          return (
            <>
              <ChoosePickupPointCard
                key={pickupPoint.id}
                value={pickupPoint.id}
                title={pickupPoint.name}
                onSelectPickupPoint={() =>
                  onSelectPickupPoint(pickupPointOption)
                }
                description={`${pickupPoint.adress}\n${pickupPoint.postalCode} ${pickupPoint.city}`}
                distance={`${safeFormattedIntlString(
                  messages.distance,
                )} ${intl.formatNumber(normalizedDistance)}${distanceUnit}`}
                price={pickupPointOption.data.transportPlan?.priceWithTax}
                logo={
                  <Img
                    alt={pickupPoint.carrierName}
                    urls={getUrl({ sellerId, pickupPointOption })}
                  />
                }
                pickupPointOption={pickupPointOption}
                isSelected={
                  pickupPoint.id === handlingValues.selectedPickupPoint?.value
                }
                transportPlan={pickupPointOption.data.transportPlan}
              />
            </>
          );
        })}
      </$Container>
    </>
  );
};

export default PickupPointList;
