import React, { FunctionComponent } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';
import { ReversToastOptions, ToastContent } from '../../Toast.types';

import { $ToastTitle } from './ToastBody.styles';

interface ToastBodyProps {
  options: ReversToastOptions;
  message?: MessageType;
  content: ToastContent;
}

const ToastBody: FunctionComponent<ToastBodyProps> = ({
  options,
  message,
  content,
}) => {
  return (
    <div>
      {(options.title || message) && (
        <$ToastTitle>
          {SafeFormattedMessage(options.title ?? message)}
        </$ToastTitle>
      )}

      {SafeFormattedMessage(content)}
    </div>
  );
};

export default ToastBody;
