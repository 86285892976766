import styled from 'styled-components';

import { ButtonLink } from '../../../button';

export const $ComplexSwitchContainer = styled.div`
  display: flex;
`;

interface $ComplexSwitchButtonProps {
  $isActive: boolean;
}

export const $ComplexSwitchRightButton = styled(
  ButtonLink,
)<$ComplexSwitchButtonProps>`
  border: 1px solid
    ${({ theme, $isActive }) => {
      if ($isActive) {
        return theme.colors.primary;
      }

      return theme.colors.disabled;
    }};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }
`;

export const $ComplexSwitchLeftButton = styled(
  ButtonLink,
)<$ComplexSwitchButtonProps>`
  border: 1px solid
    ${({ theme, $isActive }) => {
      if ($isActive) {
        return theme.colors.primary;
      }

      return theme.colors.disabled;
    }};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }
`;
