import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styled, { css } from 'styled-components';

import { ANIMATION_STATE } from '@savgroup-front-common/constants/src';

import { getAnimationDuration } from '../../../../animations/helper';
import {
  slideRightToLeft,
  StateAnimationProps,
} from '../../../../animations/stateAnimation';
import { decelerateTimingFunctionTransition } from '../../../../animations/timingFunction';
import { rem } from '../../../../helpers';
import { noticeOptions, toastOptions } from '../../Toast.config';
import { $ToastTitle } from '../ToastBody/ToastBody.styles';

interface ToastContainerProps {
  $topOffset: string;
}
interface TestProps extends StateAnimationProps {
  $height: number;
}

export const $ToastContainer = styled.div<TestProps>`
  ${slideRightToLeft};
  transition-duration: ${getAnimationDuration};
  ${decelerateTimingFunctionTransition};

  height: ${({ $height, animationState }) => {
    const willBeClosed = animationState === ANIMATION_STATE.EXITING;

    if (willBeClosed) {
      return 0;
    }

    return rem($height);
  }};
  margin-bottom: ${({ animationState }) => {
    const willBeClosed = animationState === ANIMATION_STATE.EXITING;

    if (willBeClosed) {
      return 0;
    }

    return '1rem';
  }};
`;

const toastStyle = css`
  position: relative;
  border-radius: ${({ theme }) => theme.borders.radius};
  font-size: ${rem(13)};

  .Toastify__close-button {
    color: ${({ theme }) => theme.systemColors.defaultTextColor};
  }
  .Toastify__toast-body {
    color: ${({ theme }) => theme.systemColors.defaultTextColor};

    margin: 0;
    display: flex;
    align-items: center;
    padding: 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .Toastify__toast-icon {
    min-width: 26px;
    max-height: 26px;
    height: auto;
    width: auto;
    svg {
      max-height: 26px;
      min-width: 26px;
      height: auto;
    }
  }
`;
const noticeStyle = css`
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  padding: 0;
  min-height: 36px;
  border-radius: 18px;

  ${$ToastTitle} {
    display: none;
  }
  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    display: none;
  }
  .Toastify__toast-body {
    padding: 0.5rem 1rem;
  }
`;
const $BridgeToastContainer = styled(ToastContainer)<ToastContainerProps>`
  &&&.Toastify__toast-container--top-right {
    top: ${({ $topOffset }) => $topOffset};
    padding: 0;
  }

  &&&.Toastify__toast-container--bottom-center {
    margin: 0 auto;
    width: auto;
    transform: translateX(-50%);
  }

  .${toastOptions.className} {
    ${toastStyle}
  }
  .${noticeOptions.className} {
    ${noticeStyle}
  }

  .Toastify__toast--info.${toastOptions.className} {
    background-color: ${({ theme }) => theme.colors.neutralBackground};
    .Toastify__toast-icon {
      > div {
        background-color: ${({ theme }) =>
          theme.newUI.iconColors.primary.color};
      }
      svg {
        color: ${({ theme }) => theme.newUI.iconColors.primary.color};
      }
    }
  }
  .Toastify__toast--warning.${toastOptions.className} {
    background-color: ${({ theme }) => theme.colors.neutralBackground};
    .Toastify__toast-icon {
      > div {
        background-color: ${({ theme }) =>
          theme.newUI.iconColors.caution.color};
      }
      svg {
        color: ${({ theme }) => theme.newUI.iconColors.caution.color};
      }
    }
  }
  .Toastify__toast--success.${toastOptions.className} {
    background-color: ${({ theme }) => theme.colors.neutralBackground};
    .Toastify__toast-icon {
      > div {
        background-color: ${({ theme }) =>
          theme.newUI.iconColors.success.color};
      }
      svg {
        color: ${({ theme }) => theme.newUI.iconColors.success.color};
      }
    }
  }
  .Toastify__toast--error.${toastOptions.className} {
    background-color: ${({ theme }) => theme.colors.neutralBackground};
    .Toastify__toast-icon {
      > div {
        background-color: ${({ theme }) => theme.newUI.iconColors.alert.color};
      }
      svg {
        color: ${({ theme }) => theme.newUI.iconColors.alert.color};
      }
    }
  }

  .Toastify__toast--info.${noticeOptions.className} {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .Toastify__toast--warning.${noticeOptions.className} {
    background-color: ${({ theme }) => theme.colors.alert};
  }
  .Toastify__toast--success.${noticeOptions.className} {
    background-color: ${({ theme }) => theme.colors.success};
  }
  .Toastify__toast--error.${noticeOptions.className} {
    background-color: ${({ theme }) => theme.colors.danger};
  }
`;

export default $BridgeToastContainer;
