import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

export enum INDEXING_STRATEGIES {
  WITH_UNIQUENESS_BY_PRODUCT = 'WITH_UNIQUENESS_BY_PRODUCT',
  WITHOUT_UNIQUENESS_BY_PRODUCT = 'WITHOUT_UNIQUENESS_BY_PRODUCT',
}

export const DIDACTICS_ADDITIONAL_INFORMATION_TYPES = [
  ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_INFO,
  ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_ALERT,
];

export const FULL_SIZE_FIELD = [
  ADDITIONAL_INFORMATION_TYPES.TEXT_AREA,
  ADDITIONAL_INFORMATION_TYPES.FILE,
  ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
  ADDITIONAL_INFORMATION_TYPES.CHECKBOX,
];

export const ALL_FULL_SIZE_FIELD = [
  ADDITIONAL_INFORMATION_TYPES.DATE,
  ADDITIONAL_INFORMATION_TYPES.DIDACTICS,
  ADDITIONAL_INFORMATION_TYPES.ENUM_VALUE,
  ADDITIONAL_INFORMATION_TYPES.ENUM,
  ADDITIONAL_INFORMATION_TYPES.FILE,
  ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
  ADDITIONAL_INFORMATION_TYPES.IMEI,
  ADDITIONAL_INFORMATION_TYPES.INTEGER,
  ADDITIONAL_INFORMATION_TYPES.SERIAL_NUMBER,
  ADDITIONAL_INFORMATION_TYPES.STRING,
  ADDITIONAL_INFORMATION_TYPES.TEXT_AREA,
];
