import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { BackResponse, FileId } from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/technicalIntervention/timeSlots`;

export interface TimeslotFromBack {
  start: string;
  finish: string;
  technicianIds?: string[];
}

interface GetTechnicalInterventionTimeSlotsPayload {
  fileId: FileId;
  startDate?: string;
  endDate?: string;
}

async function getTechnicalInterventionTimeSlots({
  fileId,
  startDate,
  endDate,
}: GetTechnicalInterventionTimeSlotsPayload) {
  const url = buildUrl(ENDPOINT, {
    fileId,
    startDate,
    endDate,
  });

  try {
    return await apiCall<BackResponse<TimeslotFromBack[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getTechnicalInterventionTimeSlots;
