import React, { FunctionComponent, MouseEventHandler } from 'react';
import { useMatch } from 'react-router-dom';

import { $Counter, $RouteTabLink, $RouteTabSize } from './RouteTabs.styles';

interface RouteTabsLinkProps {
  url: string;
  path: string;
  isActive?: boolean;
  count: number;
  dataTestId?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  level: 1 | 2;
  isHidden?: boolean;
  isNewUiEnabled?: boolean;
}

const RouteTabsLink: FunctionComponent<
  React.PropsWithChildren<RouteTabsLinkProps>
> = ({
  children,
  url,
  path,
  count,
  dataTestId,
  onClick,
  level,
  isHidden,
  isNewUiEnabled,
}) => {
  const isActive = useMatch(path);

  return (
    <$RouteTabSize $isNewUiEnabled={isNewUiEnabled}>
      <$RouteTabLink
        to={url}
        $isActive={!!isActive}
        $count={count}
        data-testid={`RouteTabs-${dataTestId}`}
        onClick={onClick}
        $level={level}
        $isHidden={isHidden}
        data-active={!!isActive}
        $isNewUiEnabled={isNewUiEnabled}
      >
        {children}
      </$RouteTabLink>
      {count > 0 && isNewUiEnabled && <$Counter>{count}</$Counter>}
    </$RouteTabSize>
  );
};

export default RouteTabsLink;
