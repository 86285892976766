import { APIConfiguration } from '@savgroup-front-common/configuration';
import { HANDLING_GROUPS } from '@savgroup-front-common/constants';

import { PickupPointOption } from '../../../../../../IrshPages.types';

export const getUrl = ({
  sellerId,
  pickupPointOption,
}: {
  sellerId?: string;
  pickupPointOption: PickupPointOption;
}) => {
  const carrierName = pickupPointOption.data.transportPlan.carrier
    ? pickupPointOption.data.transportPlan.carrier.toLowerCase()
    : HANDLING_GROUPS.EXTERNAL.toLowerCase();

  if (!sellerId) {
    return [
      `${APIConfiguration.catalogCDN}carriers/${carrierName}.png`,
      `${APIConfiguration.catalogCDN}carriers/${carrierName}.svg`,
    ];
  }

  return [
    `${APIConfiguration.imageCDN}sellers/${sellerId}/${carrierName}.png`,
    `${APIConfiguration.catalogCDN}carriers/${carrierName}.png`,

    `${APIConfiguration.imageCDN}sellers/${sellerId}/${carrierName}.svg`,
    `${APIConfiguration.catalogCDN}carriers/${carrierName}.svg`,
  ];
};
